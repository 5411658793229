<template>
  <div>
    <shop-head currentPageName="AGREEMENT"></shop-head>
    <div class="agreement-title container">{{ titleDesc[currentType] }}</div>
    <div class="container">
      <terms-of-use v-show="currentType === '1'"></terms-of-use>
      <privacy-policy v-show="currentType === '2'"></privacy-policy>
      <warranty-policy v-show="currentType === '3'"></warranty-policy>
      <return-policy v-show="currentType === '4'"></return-policy>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import shopHead from '../../components/shop/head'
import commonFooter from '../../components/common/footer'
import privacyPolicy from '../../components/agreement/privacyPolicy'
import termsOfUse from '../../components/agreement/termsOfUse'
import warrantyPolicy from '../../components/agreement/warrantyPolicy'
import returnPolicy from '../../components/agreement/returnPolicy'
export default {
  components: {
    shopHead,
    commonFooter,
    privacyPolicy,
    termsOfUse,
    warrantyPolicy,
    returnPolicy
  },
  data() {
    return {
      titleDesc: {
        '1': 'Terms of Use',
        '2': 'Privacy Policy',
        '3': 'Warranty policy',
        '4': 'Return policy'
      }
    }
  },
  computed: {
    currentType() {
      let type = this.$route.query.type;
      this.byScrollTop();
      return type;
    }
  },
  methods: {
    /** 回到顶部 */
    byScrollTop() {
      document.documentElement.scrollTop = 0;
      window.pageYOffset = 0; // 兼容ios
      document.body.scrollTop = 0; // 兼容低版本ie
    },
  }
}
</script>

<style lang="scss" scoped>
.agreement-title {
  width: 100%;
  line-height: 80px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #ebebeb;
}
</style>